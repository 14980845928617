import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core'
import {faChevronRight} from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import {faExternalLink} from '@fortawesome/pro-solid-svg-icons/faExternalLink'
import {faPaperPlane} from '@fortawesome/pro-solid-svg-icons/faPaperPlane'
import {faPhoneAlt} from '@fortawesome/pro-solid-svg-icons/faPhoneAlt'
import {ApplicationRoute} from '../../utils/application.route'
import {PathService} from '../../utils/path.service'

export type CallToActionType = 'Phone' | 'Email' | 'Path' | 'Url'
export interface CallToAction {
  type: CallToActionType
  value: ApplicationRoute | string
  label: string
  fragment?: string
  routeParams?: Record<string, string>
}
export type CallToActionStyle = 'button' | 'button-sm' | 'link' | 'content'
export type CallToActionButtonStyle = 'primary' | 'hero' | 'secondary'

@Component({
  selector: 'cft-call-to-action',
  templateUrl: './call-to-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallToActionComponent implements OnChanges {
  @Input() callToAction!: CallToAction
  @Input() callToActionStyle!: CallToActionStyle
  /** buttonStyle only applies if callToActionStyle is a button or small button */
  @Input() buttonStyle: CallToActionButtonStyle = 'secondary'
  @Input() showIcon = true

  _callToActionStyle!: CallToActionStyle

  @Input() anchorId?: string

  readonly phoneType: CallToActionType = 'Phone'
  readonly emailType: CallToActionType = 'Email'
  readonly pathType: CallToActionType = 'Path'
  readonly urlType: CallToActionType = 'Url'

  readonly phoneIcon = faPhoneAlt
  readonly emailIcon = faPaperPlane
  readonly pathIcon = faChevronRight
  readonly urlIcon = faExternalLink

  constructor(readonly pathService: PathService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['callToActionStyle']) {
      this.overrideStyle(changes['callToActionStyle'].currentValue)
    }
    if (changes['callToAction']) {
      this.updateDefaultStyle(changes['callToAction'].currentValue)
    }
  }

  overrideStyle(newStyle: CallToActionStyle) {
    this._callToActionStyle = newStyle
  }

  updateDefaultStyle(cta: CallToAction) {
    if (this.callToActionStyle) {
      // don't update if style is overridden by input property
      return
    }
    switch (cta.type) {
      case 'Phone':
      case 'Email':
        this._callToActionStyle = 'button-sm'
        return
      default:
        this._callToActionStyle = 'link'
        return
    }
  }

  get path(): ApplicationRoute {
    return this.callToAction.value as ApplicationRoute
  }

  get contact(): string {
    return this.callToAction.value as string
  }

  get url(): string {
    return this.callToAction.value as string
  }

  get fragment(): string | undefined {
    return this.callToAction.fragment
  }

  get routeParams(): Record<string, string> | undefined {
    return this.callToAction.routeParams
  }
}
